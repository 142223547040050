.standard-label {
  font-weight: normal;
}

.update {
  font-size: 16px;
  border-radius: 15px;

  & + & {
    margin-top: 15px;
  }
}

.update-section {
  border-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;

  padding: 10px;

  .update-danger & {
    border-color: $brand-danger;
  }
  .update-warning & {
    border-color: $brand-warning;
  }
  .update-success & {
    border-color: $brand-success;
  }
}

.update-header-footer {
  .update-danger & {
    background-color: lighten($brand-danger, 25%);
  }
  .update-warning & {
    background-color: lighten($brand-warning, 25%);
  }
  .update-success & {
    background-color: lighten($brand-success, 25%);
  }
}

.update-header {
  border-radius: 4px 4px 0 0;
  border-bottom-width: 0;
}

.update-site-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.update-site-links {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
  .update-site-links--footer {
    text-align: right;
  }
  .update-site-link {
    display: inline-block;

    & + & {
      margin-left: 10px;
    }
  }

.update-body {
  border-top-width: 0px;
  border-bottom-width: 0px;

  padding-top: 15px;
  padding-bottom: 15px;

  & p {
    margin-bottom: 0;
  }
}

.update-body-heading {
  margin-top: 0;
  margin-bottom: 5px;

  &, & * {
    vertical-align: bottom;
  }
}

.update-ssl {
  //Active SSLs should use the same color of the card so they blend in
  .update-danger &.update-ssl-status-active {
    background-color: lighten($brand-danger, 25%);
  }
  .update-warning &.update-ssl-status-active {
    background-color: lighten($brand-warning, 25%);
  }
  .update-success &.update-ssl-status-active {
    background-color: lighten($brand-success, 25%);
  }

  //SSLs that need renewal should always be warning
  &.update-ssl-status-needs-renewal {
    background-color: $brand-warning;
  }

  //SSLs that need renewal should always be warning
  &.update-ssl-status-expired {
    background-color: $brand-danger;
    .update-body-heading {
      color: white;
    }
  }
}

.update-details {
}
  .update-detail {
    line-height: 1;
  }
    .update-detail-label {
      font-weight: bold;
    }

.update-footer {
  border-radius: 0 0 4px 4px;
  border-top-width: 0;
}
.footer-update-site-links {
  vertical-align: bottom;
}

.update-footer-row--last {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.update-footer-row-right {
}
.update-footer-row + .update-footer-row {
  margin-top: 10px;
}

.stage-pickers {
}
.stage-picker {
  .update:not(.update-danger) &.btn-danger {
    background-color: lighten($brand-danger, 25%);
  }
  .update:not(.update-warning) &.btn-warning {
    background-color: lighten($brand-warning, 25%);
  }
  .update:not(.update-success) &.btn-success {
    background-color: lighten($brand-success, 25%);
  }
}
