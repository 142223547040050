$font-family-sans-serif:'Poiret One', sans-serif;
$font-family-serif:Rokkitt, serif;
$headings-font-family:$font-family-sans-serif;
$font-family-base:$font-family-serif;

$brand-primary:#D11922;
$brand-secondary:#242424;
$navbar-default-color:$brand-secondary;
$navbar-default-bg:$brand-primary;
$navbar-default-link-color:$brand-secondary;

$font-size-base:16px;

$fa-font-path:'../../fonts'
