@import "base/variables";
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "./node_modules/font-awesome/scss/font-awesome.scss";
@import url(https://fonts.googleapis.com/css?family=Poiret+One|Rokkitt:400,700);
@import "./node_modules/select2/src/scss/core";
@import "./node_modules/select2-bootstrap-theme/src/select2-bootstrap";
@import "vendor/dataTables.bootstrap";

@import 'partials/update.scss';

.filter {
  .row {
    margin-bottom:20px;
  }
}

.search-bar + .table {
  margin-top:30px;
}

th.sort-header::-moz-selection { background:transparent; }
th.sort-header::selection      { background:transparent; }
th.sort-header {
  cursor:pointer;
  }
th.sort-header::-moz-selection,
th.sort-header::selection {
  background:transparent;
  }
table th.sort-header:after {
  content:'';
  float:right;
  margin-top:7px;
  border-width:0 4px 4px;
  border-style:solid;
  border-color:#404040 transparent;
  visibility:hidden;
  }
table th.sort-header:hover:after {
  visibility:visible;
  }
table th.sort-up:after,
table th.sort-down:after,
table th.sort-down:hover:after {
  visibility:visible;
  opacity:0.4;
  }
table th.sort-up:after {
  border-bottom:none;
  border-width:4px 4px 0;
  }
